/*
@contract email-capture-modal {
  width: Width
}
*/

.captureModal {
  --email-capture-form-layout-flex-direction: column;
  --email-capture-privacy-policy-font-size: var(--font-size-sm);
  --modal-close-button-padding: var(--space-3x);
  --modal-close-button-font-size: var(--font-size-2xl);
  --modal-panel-border-radius: var(--border-radius-2x);
  --heading-margin: 0 0 var(--space-2x);
  --heading-font-size: var(--font-size-lg);
  position: fixed;
  z-index: var(--z-index-2x);
  bottom: 75px;
  margin: 0 auto;
  left: 50%;
  max-width: var(--email-capture-modal-width);
  transform: translateX(-50%);
  width: calc(100% - var(--space-4x));

  @media (--screen-md) {
    --modal-close-button-font-size: var(--font-size-lg);
    --modal-close-button-padding: var(--space-2x);
    bottom: 30px;
  }
}
