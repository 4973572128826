@layer components {
  .wheel {
    color: var(--color-black);
    height: 150px;
    position: relative;
    width: 100%;
    font-size: var(--font-size-body);
    user-select: none;
  }

  .inner {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 39%;
  }

  .slides {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    transform: translateY(-59px);
  }

  .slides::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .shadowTop,
  .shadowBottom {
    left: 0;
    height: 60px;
    width: 100%;
    position: absolute;
    z-index: 1;
    pointer-events: none;
  }

  .shadowTop {
    background: linear-gradient(
      to bottom,
      rgb(255 255 255) 0%,
      rgb(255 255 255 / 30%) 100%
    );
    border-bottom: 1px solid var(--color-gray-200);
    top: 0;
  }

  .shadowBottom {
    background: linear-gradient(
      to bottom,
      rgb(255 255 255 / 30%) 0%,
      rgb(255 255 255) 100%
    );
    border-top: 1px solid var(--color-gray-200);
    bottom: 0;
  }

  .slide {
    align-items: center;
    backface-visibility: hidden;
    display: flex;
    height: 30px;
    width: 100%;
    justify-content: center;
    scroll-snap-align: center;
  }
}
