.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: initial;
  padding: initial;
  margin: initial;
}

.subText,
.optionalText,
.promptText {
  font-family: var(--font-family-body);
}

.subText,
.optionalText {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-gray-500);
}

.promptText {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: var(--color-black);
  padding: initial;
}

.subText {
  margin-top: 4px;
  line-height: 21px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  gap: var(--toggle-button-group-gap);
}

.buttonsContainer div {
  width: 100%;
}

.buttonsContainer label {
  padding: 0 var(--space-1x);
}
