.confirmDeleteSavedSearchModal {
  --modal-padding: var(--space-10x) var(--space-6x) var(--space-6x);
  --modal-panel-position: relative;
  --modal-panel-wrap-padding: 0;
  --modal-panel-wrap-align-items: flex-end;
  --modal-panel-border-radius: 0;
  --modal-panel-align-items: center;
  --modal-container-overflow: initial;
  --anchor-font-size: var(--font-size-body);

  @media (--screen-md) {
    --modal-width: 390px;
    --modal-margin: auto;
  }
}

.heading {
  margin-top: var(--space-2x);
  margin-bottom: var(--space-5x);
}

.subheading {
  font-size: var(--font-size-body);
  margin-bottom: var(--space-5x);
  text-align: center;
  line-height: var(--space-5x);
  width: var(--space-64x);
}

.deleteButton {
  font-size: var(--font-size-body);
  width: 100%;
}

.closeIcon {
  font-size: var(--space-6x);
}

.loadingSpinner {
  --spinner-accent-color: var(--color-white);
}
