@layer components {
  .toggleButtonInput {
    display: none;
    height: 0;
    width: 0;
  }

  .toggleButtonLabel {
    align-items: center;
    padding: var(--toggle-button-padding);
    background-color: var(--color-white);
    border-color: var(--toggle-button-border-color);
    border-radius: 0.25rem;
    border-style: solid;
    border-width: var(--toggle-button-border-width);
    box-sizing: border-box;
    color: var(--toggle-button-color);
    cursor: pointer;
    display: inline-flex;
    font-size: var(--font-size-body);
    font-weight: var(--toggle-button-font-weight);
    justify-content: center;
    list-style: none;
    min-height: var(--toggle-button-min-height);
    text-align: center;
    white-space: nowrap;
    width: 100%;
  }

  @media (hover: hover) {
    .toggleButtonLabel:hover {
      background-color: var(--toggle-button-hover-background-color);
      color: var(--toggle-button-hover-color);
      border-color: var(--toggle-button-hover-border-color);
    }

    .toggleButtonInput:checked ~ .toggleButtonLabel:hover {
      background-color: var(--toggle-button-background-color-selected-hover);
      color: var(--toggle-button-color-selected-hover);
      border-color: var(--toggle-button-selected-hover-border-color);
    }
  }

  .toggleButtonInput:checked ~ .toggleButtonLabel {
    background-color: var(--toggle-button-background-color-selected);
    color: var(--toggle-button-color-selected);
    border-color: var(--toggle-button-border-color-selected);
    font-weight: var(--toggle-button-font-weight-selected);
  }

  .toggleButtonInput:focus ~ .toggleButtonLabel {
    outline: Highlight auto 1px;
    outline: -webkit-focus-ring-color auto 1px;
  }

  .toggleButtonGroup {
    display: flex;
    gap: var(--toggle-button-group-gap);
  }

  .toggleButtonGroupVertical {
    flex-direction: column;
  }

  .toggleButtonGroupItem {
    flex: 1;
  }
}
