.wheelContainer {
  min-height: 185px;
}

.toggleSection {
  display: flex;
  flex-direction: column;
  gap: var(--space-3x);
  padding: var(--space-3x) 0;
  overflow: auto;
  --toggle-button-group-gap: var(--space-3x);
}

.dropDownContainer {
  --select-height: var(--space-12x);
  --select-item-height: var(--space-12x);
  --select-height-focused: var(--space-12x);
}
