.captureModal {
  --email-capture-padding: 0;
  --email-capture-margin-top: var(--space-5x);
  --email-capture-border: none;
  --email-capture-form-section-width: 100%;
  --email-capture-privacy-policy-padding: 0 10px;
  --email-capture-privacy-policy-font-size: var(--font-size-sm);
  --email-capture-modal-width: 350px;
  --email-capture-gap: var(--space-5x);
  --email-capture-flex-direction: column;
  --email-capture-flex-direction-screen-md: column;
  --email-capture-form-layout-flex-direction: column;
  --email-capture-form-layout-flex-direction-screen-md: column;
  --email-capture-form-layout-inner-flex-direction: column;
  --email-capture-form-layout-inner-gap: var(--space-5x);
  --heading-margin: 0 0 var(--space-2x);
  --heading-font-size: var(--font-size-lg);
  --icon-button-width: 100%;
  --email-capture-border-left: 0;
  --email-capture-text-align: center;
  --email-capture-form-layout-gap: 0;
  --modal-width: 350px;
  --modal-height: fit-content;
  --modal-margin: auto;
  --modal-panel-border-radius: var(--border-radius-2x);
  --modal-padding: var(--space-10x) var(--space-8x) var(--space-8x);
  --button-width: 100%;
}

.socialButtons {
  display: grid;
  gap: var(--space-2x);
  padding-top: var(--space-6x);
}
