.template {
  --horizontal-scroll-z-index: 0;
  --horizontal-scroll-list-container-overflow-x: hidden;
  --horizontal-scroll-container-padding: 0;
  --horizontal-scroll-arrow-button-top: 30px;
  position: relative;
  --button-font-size: var(--font-size-body);
  line-height: var(--leading-tight);
  --input-textarea-height: var(--space-14x);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  @media (--screen-md) {
    overflow: visible;
    --horizontal-scroll-container-padding: 0 var(--space-2-5x);
  }
}

.modalBody {
  height: 100%;
  padding: 0 var(--space-6x);
}

.headingContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--space-3x);
  justify-content: center;
  align-items: center;
  gap: var(--space-4x);
  top: 0;
  position: sticky;
  background-color: white;
}

.question {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-semibold);
  color: var(--color-black);
}

.stepsText {
  font-size: var(--font-size-body);
}

.footer {
  background-color: white;
  box-shadow: var(--shadow-100-up);
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-4x) var(--space-6x) var(--space-6x);
  --button-min-width: 176px;
  gap: var(--space-4x);
}
