@layer components {
  .anchorListDisclosureList {
    padding: 0;
    list-style: none;
  }

  .anchorListDisclosureListItem {
    padding-right: var(--space-3x);
    padding-bottom: var(--space-3x);
  }

  .anchorListDisclosureLink {
    display: inline-flex;
    padding: 0;

    @media (--screen-md) {
      font-size: var(--font-size-md);
    }
  }

  .anchorListDisclosureItemHidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
