.onboardingModal {
  --modal-panel-wrap-padding: 0;
  --modal-padding: 0;
  --modal-height: 100%;

  --toggle-button-hover-color: var(--toggle-button-modal-hover-color);
  --toggle-button-border-color: var(--toggle-button-modal-border-color);
  --toggle-button-hover-border-color: var(
    --toggle-button-modal-hover-border-color
  );
  --toggle-button-min-height: var(--space-14x);
  font-size: var(--font-size-body);

  @media (--screen-md) {
    --modal-padding: 0;
    --modal-width: 375px;
    --modal-height: 610px;
  }
}

.onboardingModalHeader {
  padding: 0 var(--space-6x);
  min-height: 62px;
}
