.container {
  display: flex;
  flex: 1;
  margin-bottom: 46px;

  @media (--screen-md) {
    margin-bottom: 0;
  }
}

.containerMiddle {
  @media (--screen-md) {
    justify-content: center;
  }
}

.containerEnd {
  margin-bottom: 0;

  @media (--screen-md) {
    justify-content: flex-end;
  }
}

.dropdown {
  display: inline-block;
  padding-right: 51px;
}

.dropdownMiddle {
  justify-content: center;
}

.dropdownEnd {
  justify-content: flex-end;
}

.title {
  --button-height: 12px;
  --button-font-size: var(--font-size-sm);
  --button-padding: 0;
  display: flex;
  align-items: center;
  gap: var(--space-1x);
}

.heading {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  line-height: 20px;
}

.button {
  font-size: var(--font-size-sm);
  line-height: 20px;
  color: var(--color-black);
}

.list {
  display: flex;
  flex-direction: column;
  gap: var(--space-3x);
  list-style-type: none;
  padding: 0;
}

.listItem {
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-semibold);
  line-height: 20px;

  &:first-of-type {
    margin-top: var(--space-3x);
  }
}

.hidden {
  display: none;
}

.link {
  font-size: var(--font-size-sm);
}
