.contentWrapper {
  --prompts-hr-margin: 24px 0;
  padding: var(--enhanced-lead-questions-content-wrapper-padding);
  flex: 1;
}

.form {
  display: var(--enhanced-lead-questions-form-display);
  flex: var(--enhanced-lead-questions-form-flex);
  flex-direction: var(--enhanced-lead-questions-form-flex-direction);
}

.contentWrapper > :last-child {
  margin-bottom: 36px;
}

.heading {
  --heading-margin: 0 0 var(--space-8x) 0;
  line-height: var(--leading-normal);
}

.footer {
  --button-font-size: var(--font-size-body);
  display: flex;
  box-shadow: var(--lead-form-footer-box-shadow);
  align-items: flex-end;
  justify-content: center;
  padding: var(--enhanced-lead-questions-footer-padding);
  margin: var(--enhanced-lead-questions-footer-margin);
}
