@layer components {
  .rentTerms {
    font-size: var(--font-size-xs);
    text-align: center;
  }

  .rentTermsCopyRight {
    @media (--screen-md) {
      display: inline;
    }
  }
}
