@layer components {
  .anchor {
    align-items: var(--anchor-placement);
    color: var(--anchor-text-color-default);
    display: var(--anchor-display);
    flex-direction: var(--anchor-direction);
    font-size: var(--anchor-font-size);
    font-weight: var(--anchor-weight);
    gap: var(--anchor-gap);
    justify-content: var(--anchor-placement);
    padding: var(--anchor-padding);
    position: var(--anchor-position);
    text-decoration: var(--anchor-text-decoration-default);
    text-align: var(--anchor-text-alignment);
    z-index: var(--anchor-z-index);
    border-radius: var(--anchor-border-radius);
    align-self: var(--anchor-align-self);
    line-height: var(--anchor-line-height);

    /* When we want a button to visually look like an anchor, reset button defaults. */
    &:is(button) {
      appearance: none;
      background-color: inherit;
      border: 0;
      font-family: inherit;
    }

    &:hover {
      color: var(--anchor-text-color-hover);
      text-decoration: var(--anchor-text-decoration-hover);
      background-color: var(--anchor-background-color-hover);
    }

    &:active {
      color: var(--anchor-text-color-active, var(--anchor-text-color-hover));
      text-decoration: var(--anchor-text-decoration-hover);
    }

    &:disabled {
      color: var(--anchor-text-color-disabled);
      text-decoration: var(--anchor-text-decoration-default);
    }
  }

  .onDark {
    --anchor-text-color-default: var(--anchor-text-color-dark-default);

    &:hover {
      --anchor-text-color-hover: var(--anchor-text-color-dark-hover);
    }

    &:active {
      --anchor-text-color-active: var(--anchor-text-color-dark-active);
    }
  }
}
