/*
@contract email-capture {
  background: BackgroundColor
  text-align: TextAlign
  top-margin: Margin
  form-section-width: Width
  gap: Gap
  privacy-policy {
    padding: Padding
    font-size: FontSize
    line-height: LineHeight
  }
  description-padding: Padding
  flex-direction: FlexDirection
  flex-direction-screen-md: FlexDirection
  form {
    flex-direction: FlexDirection
    flex-direction-screen-md: FlexDirection
    layout {
      flex-direction: FlexDirection
      flex-direction-screen-md: FlexDirection
      inner-flex-direction: FlexDirection
      inner-gap: Gap
      gap: Gap
    }
    button-disabled {
      color: Color
      background-color: BackgroundColor
    }
  }
  padding: Padding
  border: Border
  border-left: BorderLeft
}
*/

.privacyPolicy {
  font-size: var(--email-capture-privacy-policy-font-size);
  line-height: var(--email-capture-privacy-policy-line-height);
  color: var(--color-gray-500);
  text-align: center;
  padding: var(--email-capture-privacy-policy-padding);
}

.captureForm {
  background: var(--color-white);
  border: var(--email-capture-border);
  border-radius: var(--border-radius-2x);
  border-left: var(--email-capture-border-left);
  padding: var(--email-capture-padding);
  display: flex;
  flex-direction: var(--email-capture-flex-direction);
  gap: var(--email-capture-gap);
  text-align: var(--email-capture-text-align);
  margin-top: var(--email-capture-margin-top);

  @media (--screen-md) {
    flex-direction: var(--email-capture-flex-direction-screen-md);
  }
}

.emailFieldCapture {
  width: 100%;
  min-width: 170px;
}

.captureFormLayout {
  display: flex;
  gap: var(--email-capture-form-layout-gap);
  flex-direction: var(--email-capture-form-layout-flex-direction);

  @media (--screen-md) {
    flex-direction: var(--email-capture-form-layout-flex-direction-screen-md);
    align-items: center;

    & > div {
      width: var(--email-capture-form-section-width);
    }
  }
}

.captureFormLayoutInner {
  --button-primary-disabled-color: var(
    --email-capture-form-button-disabled-color
  );
  --button-primary-disabled-background-color: var(
    --email-capture-form-button-disabled-background-color
  );
  display: flex;
  flex-direction: var(--email-capture-form-layout-inner-flex-direction);
  gap: var(--email-capture-form-layout-inner-gap);
}

.columnsCapture {
  display: flex;
  margin-bottom: var(--space-1x);
  flex-direction: column;
  gap: var(--space-3x);

  @media (--screen-sm) {
    display: flex;
    flex-direction: row;
  }
}

.collectEmail {
  display: flex;
  gap: var(--space-1x);
  margin-top: var(--space-1x);
}

.captureFormDescription {
  font-size: var(--font-size-body);
  color: var(--color-gray-500);
  padding: var(--email-capture-description-padding);
}
