@layer components {
  .select {
    align-items: center;
    background-color: var(--select-background-color);
    border-radius: var(--select-border-radius);
    border: var(--select-border-width) solid var(--select-border-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-height: var(--select-height);
    min-width: var(--select-min-width);
    position: var(--select-position);
    white-space: nowrap;
    width: var(--select-width);
    transition: all 100ms cubic-bezier(0, 0, 0.2, 1);
    transition-property: color, background-color, border-color;

    &:hover {
      background-color: var(--select-background-color-hover);
      border-color: var(--select-border-color-hover);
    }
  }

  .selectTrigger {
    align-items: var(--select-align-items);
    color: var(--select-trigger-color);
    display: var(--select-display);
    gap: var(--select-trigger-gap);
    justify-content: var(--select-justify-content);
    padding: var(--select-padding);
    width: 100%;
    height: var(--select-height);
  }

  .selectTrigger[data-headlessui-state='open'] {
    height: var(--select-height-focused);
  }

  .selectIcon {
    display: inherit;
    color: var(--select-icon-color);
    width: 14px;
    height: 14px;
  }

  .selectResults {
    background-color: var(--color-white);
    box-shadow: var(--shadow-200);
    color: var(--select-font-color);
    left: 0;
    list-style: none;
    max-height: 300px;
    min-width: 100px;
    overflow-y: auto;
    position: absolute;
    text-align: left;
    top: calc(var(--select-height-focused) + 1px);
    width: var(--select-results-width);
    z-index: var(--select-z-index);
    padding: var(--select-results-padding);
    display: grid;
    gap: var(--space-1x);
  }

  .selectItem {
    align-items: center;
    background-color: var(--select-item-background-color);
    border-radius: 0.25rem;
    color: var(--select-item-color);
    cursor: pointer;
    display: flex;
    min-height: var(--select-item-height);
    width: 100%;
    padding: var(--select-item-padding);
    text-indent: var(--space-2x);

    &:hover {
      background-color: var(--select-item-background-color-hover);
    }
  }

  .selectItemActive {
    background-color: var(--select-item-background-color-active);

    &:hover {
      background-color: var(--select-item-background-color-active-hover);
    }
  }

  .selectItemSelected {
    background-color: var(--select-item-background-color-selected);
    color: var(--select-item-color-selected);

    &:hover {
      background-color: var(--select-item-background-color-selected-hover);
      color: var(--select-item-color-selected);
    }
  }

  .selectItemDisabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }

  .hidden {
    display: none;
  }
}
